<template>
  <div class="mini_game_bet">
    <div class="mini_game">
      <leisure-game-ifram-view>
        <iframe id="gamefram" scrolling="no" frameborder="0"
                :src="iframsutdaUrl"></iframe>
      </leisure-game-ifram-view>
      <div class="game_tab">
        <leisure-bet-masker :show-masker="showMasker"></leisure-bet-masker>
        <leisure-timer-comp
                ref="ltimer"
                :leisure-game-info="leisureGameInfo"
                @timerStop="timerStop" @refreshTimer="refreshTimer">
          <span slot="title" class="text-orange"><i class="fa fa-star"></i>크라운 섯다 </span>
        </leisure-timer-comp>

        <!--선택부분-->
        <div class="mini_bet_box">
          <div class="mg">
            <span class="t">섯다</span>
            <div class="mg_btn b2"
                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SUTDA_NORMAL && selectedInfo.selectedWay === 1}"
                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_SUTDA_NORMAL,leisureGameInfo.leisureGame.id,1, normalConfig.odds1, normalConfig, '한국')">
              <div class="r rblue">
                <span class="n">Korea</span>
                <span class="b">{{normalConfig.odds1}}</span>
              </div>
            </div>

            <div class="mg_btn b2"
                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SUTDA_NORMAL && selectedInfo.selectedWay ===2}"
                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_SUTDA_NORMAL,leisureGameInfo.leisureGame.id,2,  normalConfig.odds2, normalConfig, '일본')">
              <div class="r rred">
                <span class="n">Japan</span>
                <span class="b">{{normalConfig.odds2}}</span>
              </div>
            </div>
          </div>
        </div>

        <!--파워볼 베팅카트-->
        <leisure-bet-cart-comp
                ref="betCart" :kind="kind"
                :selected-info="selectedInfo">
          크라운 섯다
        </leisure-bet-cart-comp>

        <!--최근 배팅내역-->
        <leisure-lately-bet-list-comp
                ref="latelyBetList"
                :kind="kind"></leisure-lately-bet-list-comp>

      </div>
    </div>
  </div>
</template>

<script>
  import SubTitle from "../../../components/SubTitle";
  import LeisureTimerComp from "../../../components/leisuregame/LeisureTimerComp";
  import LeisureBetCartComp from "../../../components/leisuregame/LeisureBetCartComp";
  import leisureConst from "../../../common/leisureConst";
  import sportsConst from "../../../common/sportsConst";
  import LeisureBetMasker from "../../../components/leisuregame/LeisureBetMasker";
  import {leisureGameMixin} from "../../../common/mixin";
  import {getLeisureGameAttributeConfig} from "../../../network/leisureRequest";
  import LeisureLatelyBetListComp from "../../../components/leisuregame/LeisureLatelyBetListComp";
  import LeisureGameView from "../../../components/leisuregame/LeisureGameView";
  import LeisureGameLinks from "../LeisureGameLinks";
  import LeisureGameIframView from "../LeisureGameIframView";
  import LeisureCompGameLinks from "../LeisureCompDonghangGameLinks";
  import {RECEIVE_CURRENTLEISUREGAMECOMP, RECEIVE_CURRENTLEISUREGAMEID} from "../../../store/mutation-types";

  export default {

    name: "CrownSutda",
    mixins:[leisureGameMixin],
    components: {
      LeisureCompGameLinks,
      LeisureGameIframView,
      LeisureGameLinks,
      LeisureGameView,
      LeisureLatelyBetListComp, LeisureBetMasker, LeisureBetCartComp, LeisureTimerComp, SubTitle},
    data() {
      return {
        kind: leisureConst.LEISURE_KIND_SUTDA,
        iframsutdaUrl:'',
      }
    },

    methods:{
      initLeisureGameArttributeConfig() {
        getLeisureGameAttributeConfig().then(res => {
          if (res.data.success) {
            this.attrConfig = res.data.data

            //배당설정
            this.normalConfig = this.attrConfig.find(attr => {
              return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_SUTDA_NORMAL)
            })
          }
        })
      }
    },
    created() {
      if(this.$store.state.ismobile){
        this.iframsutdaUrl = 'http://str01.crown-api02.com/iframesutda/skin1/mobile';
      }else {
        this.iframsutdaUrl = 'http://str01.crown-api02.com/iframesutda/skin1/pc';
      }
      this.initKindConfig(this.kind)
      this.$store.commit(RECEIVE_CURRENTLEISUREGAMECOMP,leisureConst.LEISURE_COMP_CROWN);
      this.$store.commit(RECEIVE_CURRENTLEISUREGAMEID,this.kind);
    },
  }
</script>

<style scoped>
  @media screen and (max-width: 1024px) {
    #gamefram{
      width: 100% !important;
      height: 650px!important;
      border: 0;
      position: absolute;
      transform: scale(1)!important;
      left: 0!important;
      top: 0!important;
    }
    .mini_game .game_tab{
       margin: 320px 15px 15px 15px!important;
    }
    .mini_fram{
      min-height: 350px!important;
    }
  }

</style>